import { useState, useEffect } from "react"
import Logo from "../common/logo"
import Svg from "../common/svg"
import { Container8xl } from "./container"
import Modal from "@common/modal/modal"
import PropTypes from "prop-types"
import LinkButton from "@common/buttons/linkButton"

Header.propTypes = {
  logoObj: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    viewBox: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  mobileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  siteName: PropTypes.string.isRequired,
  primaryColorClass: PropTypes.string,
  isPrimaryCta: PropTypes.bool,
  isSecondaryCta: PropTypes.bool,
  primaryCta: PropTypes.string,
  secondaryCta: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
}

Header.defaultProps = {
  links: [],
  primaryColorClass: "btn-blue",
  isPrimaryCta: true,
  isSecondaryCta: true,
  primaryCta: "Sign up",
  secondaryCta: "Sign in",
}

export default function Header({
  logoObj,
  links,
  siteName,
  primaryColorClass,
  isPrimaryCta,
  isSecondaryCta,
  primaryCta,
  secondaryCta,
  primaryCtaLink,
  secondaryCtaLink,
  mobileLinks, //if present > will override menu inside hamburger menu (mobile menu)
  isDarkMode,
}) {
  const [isOpen, setIsOpen] = useState(false) // for mobile header

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("overflow-hidden")
    else document.body.classList.remove("overflow-hidden")
  }, [isOpen])

  return (
    <div className="relative  backdrop-blur-md">
      <Container8xl>
        <div className="flex justify-between items-center py-4  lg:space-x-10">
          {renderLogo()}

          {/* Mobile Menu Button */}
          <div className="-mr-2 -my-2 lg:hidden" onClick={() => setIsOpen(true)}>
            <button
              type="button"
              className="bg-background text-foreground rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-0 focus:ring-inset"
            >
              <span className="sr-only">Open menu</span>
              <Svg name="menu" classes="h-6 w-6" />
            </button>
          </div>

          {/* Links */}
          <nav className="hidden lg:flex space-x-10">
            {links?.map((link, i) => (
              <LinkButton
                target="_blank"
                key={i}
                href={link.link}
                ariaLabel="link"
                rel="noreferrer"
                variant={"gray"}
              >
                {link.name}
              </LinkButton>
            ))}
          </nav>
          {renderActions()}
        </div>
      </Container8xl>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderMobileMenu()}
      </Modal>
    </div>
  )

  function renderActions() {
    return (
      <div className="hidden lg:flex items-center justify-end space-x-8  lg:w-0">
        {isSecondaryCta == false ? null : (
          <LinkButton
            href={secondaryCtaLink || "/login"}
            variant={"minimal"}
            aria-label="login"
          >
            {secondaryCta || "Sign in"}
          </LinkButton>
        )}
        {isPrimaryCta == false ? null : (
          <LinkButton
            href={primaryCtaLink || "/signup"}
            aria-label="signup"
            variant={"primary"}
          >
            {primaryCta || "Sign up"}
          </LinkButton>
        )}
      </div>
    )
  }

  function renderLogo() {
    return (
      <div className="flex justify-start lg:w-0 ">
        {/* <a href="/"> */}
        <span className="sr-only">{siteName}</span>
        <Logo logoObj={logoObj} className="h-8" isDarkMode={isDarkMode} url="/" />
        {/* </a> */}
      </div>
    )
  }

  function renderMobileMenu() {
    return (
      <div className="flex flex-col h-[95vh] w-[95vw]">
        <div className="pt-5 pb-6 px-5 space-y-6 flex-grow">
          {/* header */}
          <div className="flex items-center justify-between">
            {renderLogo()}
            <div className="-mr-2" onClick={() => setIsOpen(false)}>
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none"
              >
                <Svg name="cross" classes="h-6 w-6" />
              </button>
            </div>
          </div>

          {/* body */}
          <nav className="grid gap-y-8">
            {(mobileLinks ?? links)?.map((link, i) => {
              return (
                <a
                  aria-label="link"
                  key={i}
                  href={link.link}
                  className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 link"
                >
                  <Svg
                    name={link.icon}
                    classes="flex-shrink-0 h-6 w-6 text-blue-theme"
                  />
                  <div className="text-base leading-6 font-medium text-gray-900">
                    {link.name}
                  </div>
                </a>
              )
            })}
          </nav>
          {/* secondary links */}
          {/*
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {
                links.map((link, i) => <a key={i} href="#" className="link text-gray-900"> {link.name} </a>)
              }
            </div>
            */}
        </div>

        {/* footer */}
        <div className="space-y-6 py-6 px-5">
          <div className="space-y-6">
            {isPrimaryCta == false ? null : (
              <span className="flex w-full rounded-md shadow-sm">
                <a
                  href={primaryCtaLink || "/signup"}
                  className={
                    "btn flex w-full " +
                    (primaryColorClass ? primaryColorClass : "btn-blue")
                  }
                  aria-label="signup"
                >
                  {primaryCta || "Sign up"}
                </a>
              </span>
            )}
            {isSecondaryCta == false ? null : (
              <p className="text-center text-base font-medium leading-6 text-gray-500">
                Existing customer?&nbsp;
                <a
                  href={secondaryCtaLink || "/login"}
                  className="text-blue-theme transition duration-150 ease-in-out hover:text-blue-500"
                  aria-label="login"
                >
                  {secondaryCta || "Sign in"}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

import { buttonBaseStyles, variants } from "@common/buttons/button"
import Svg from "@common/svg"
import { cva, type VariantProps } from "class-variance-authority"
import { ReactNode } from "react"

interface ButtonBaseProps {
  children: ReactNode | undefined
  href: string
  ariaLabel?: string | undefined
  target?: "_blank" | "_self" | "_parent" | "_top" | undefined
  rel?:
    | ""
    | "alternate"
    | "author"
    | "bookmark"
    | "external"
    | "help"
    | "license"
    | "next"
    | "nofollow"
    | "noopener"
    | "prev"
    | "search"
    | "tag"
    | "noreferrer"
    | undefined
}

const linkVariants = cva(buttonBaseStyles, {
  variants: {
    ...variants, //🚦 borrowed base and buttonBaseStyles variants form Button.tsx for links that look like button
    variant: {
      ...variants.variant,
      link: "text-blue-700 ",
      gray: " text-secondary hover:text-gray-900 dark:hover:text-gray-200 ",
    },
  },
  defaultVariants: {
    size: "default",
    variant: "link",
    width: "default",
  },
})

export interface ButtonProps
  extends ButtonBaseProps,
    VariantProps<typeof linkVariants> {}

export default function LinkButton({
  children,
  variant,
  href,
  ariaLabel = "link",
  target,
  rel,
  width,
}: ButtonProps) {
  return (
    <a
      target={target}
      className={linkVariants({ variant, width })}
      href={href}
      aria-label={ariaLabel}
      rel={rel}
    >
      {children}
      {target == "_blank" ? (
        <div className="hidden group-hover:block animate-mildFade transform top-1 -right-0 absolute ">
          <Svg
            strokeWidth={2}
            classes="w-4 h-3"
            name="external-link"
            tooltipContent={undefined}
          />
        </div>
      ) : null}
    </a>
  )
}

import React from "react"
const { createRef, useState, useEffect } = React

// todo 1 - currently root element is viewport (default)
// accept custom root elements to make the section stick within another element
// todo 2 - gneralize for any position (top, bottom, left, right)
// todo 3 - callback for isStickyChange

export default function Sticky({ children, classes, stickyClasses, ...rest }) {
  const [isSticky, setIsSticky] = useState(false)
  const ref = React.createRef()

  // mount
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      { threshold: [1] }
    )

    observer.observe(cachedRef)

    // unmount
    return function () {
      observer.unobserve(cachedRef)
    }
  }, [ref])

  return (
    <div
      style={{ top: "-1px" }}
      className={(classes ? classes : " ") + " z-30 sticky " + (isSticky ? stickyClasses : "")}
      ref={ref}
      {...rest}
      id="sticky_header"
    >
      {children}
    </div>
  )
}
